@use "sass:map";
@import "./variables";
@import "./typography";

@each $profile,
$colour in $colourProfiles {
  .color-#{$profile} {
    color: #{$colour} !important;
  }

  .bg-#{$profile} {
    background-color: #{$colour} !important;
  }
}

@each $name,
$colour in $colours {
  .color-#{$name} {
    color: #{$colour} !important;
  }

  .bg-#{$name} {
    background-color: #{$colour} !important;
  }
}

@each $label,
$scale in $paddingScales {
  .p-#{$label} {
    padding-left: #{$paddingBase * $scale} !important;
    padding-top: #{$paddingBase * $scale} !important;
    padding-right: #{$paddingBase * $scale} !important;
    padding-bottom: #{$paddingBase * $scale} !important;
  }

  .p-x-#{$label} {
    padding-left: #{$paddingBase * $scale} !important;
    padding-right: #{$paddingBase * $scale} !important;
  }

  .p-y-#{$label} {
    padding-top: #{$paddingBase * $scale} !important;
    padding-bottom: #{$paddingBase * $scale} !important;
  }

  .p-left-#{$label} {
    padding-left: #{$paddingBase * $scale} !important;
  }

  .p-top-#{$label} {
    padding-top: #{$paddingBase * $scale} !important;
  }

  .p-right-#{$label} {
    padding-right: #{$paddingBase * $scale} !important;
  }

  .p-bottom-#{$label} {
    padding-bottom: #{$paddingBase * $scale} !important;
  }
}

@each $label,
$scale in $marginScales {
  .m-#{$label} {
    margin-left: #{$marginBase * $scale} !important;
    margin-top: #{$marginBase * $scale} !important;
    margin-right: #{$marginBase * $scale} !important;
    margin-bottom: #{$marginBase * $scale} !important;
  }

  .m-x-#{$label} {
    margin-left: #{$marginBase * $scale} !important;
    margin-right: #{$marginBase * $scale} !important;
  }

  .m-y-#{$label} {
    margin-top: #{$marginBase * $scale} !important;
    margin-bottom: #{$marginBase * $scale} !important;
  }

  .m-left-#{$label} {
    margin-left: #{$marginBase * $scale} !important;
  }

  .m-top-#{$label} {
    margin-top: #{$marginBase * $scale} !important;
  }

  .m-right-#{$label} {
    margin-right: #{$marginBase * $scale} !important;
  }

  .m-bottom-#{$label} {
    margin-bottom: #{$marginBase * $scale} !important;
  }
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-0 {
  flex: 0 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.gap-1 {
  gap: 0.5em;
}

.gap-2 {
  gap: 1.0em;
}

.gap-3 {
  gap: 1.5em;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.pos-relative {
  position: relative !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.w-max-none {
  max-width: none !important;
}

.h-max-none {
  max-height: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-visible {
  overflow: visible !important;
}

.dropIn {
  animation-name: dropIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.font-body {
  @extend %font-body;
}

.font-subheading {
  @extend %font-subheading;
}

.font-highlightlink {
  @extend %font-hightlightlink;
}

.font-heading {
  @extend %font-heading;
}

.font-title {
  @extend %font-title;
}

.font-subtitle {
  @extend %font-subtitle;
}

.font-smallprint {
  @extend %font-smallprint;
}

.font-input {
  @extend %font-body;
  @extend %font-style-light;
}

.font-style-light {
  @extend %font-style-light;
}

.font-style-regular {
  @extend %font-style-regular;
}

.font-style-header {
  @extend %font-style-header;
}

.btn {
  appearance: none;
  margin: 0;
  padding: 0.75em;
  margin-top: 1rem;
  border-radius: $borderRadius;
  width: 160px;
  height: auto;
  outline: none;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: inherit;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.btn-primary {
  border-color: #{map.get($colourProfiles, 'primary')};
  background: #{map.get($colourProfiles, 'highlight')};
  color: #{map.get($colourProfiles, 'highlight-text')};
  cursor: pointer;
  user-select: none;
}

.btn-secondary {
  border-color: #{map.get($colourProfiles, 'text')};
  background: #{map.get($colourProfiles, 'text')};
  color: #{map.get($colourProfiles, 'highlight-text')};
}

.btn-hollow {
  background: transparent;

  &.btn-primary {
    border-color: #{map.get($colourProfiles, 'text')};
    color: #{map.get($colourProfiles, 'text')};
  }

  &.btn-secondary {
    border-color: #{map.get($colourProfiles, 'highlight-text')};
    color: #{map.get($colourProfiles, 'highlight-text')};
  }
}


.btn-link {
  all: unset;
  cursor: pointer;
  color: #2391C1;
  &:hover{
    text-decoration: underline;
  }
}

.btn-disabled {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.round-corners {
  border-radius: 0.625em;
}

.edge-corners-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.625em;
  background-color: #{map.get($colours, 'white')};
  padding: 1em;
  margin-bottom: 1em;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-bullets {
  list-style-type: none !important;
}

.highlight {
  background: #{map.get($colourProfiles, "highlight")} !important;
  color: #{map.get($colourProfiles, "highlight-text")} !important;
}

.no-appearence {
  appearance: none;
  outline: none;
  border: none;
  background: none;
}



.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem ;
  align-items: stretch;

  > .col {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    width: calc(100% - 2rem);

    @media screen and (min-width:1200px) {
      width: calc(50% - 2rem);
    }       

    > div {
      width: 100%;
    }
  }
}