@use "sass:math";
@use "sass:map";
@import "./variables";

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-font-size, $max-font-size) {
  & {
    font-size: $min-font-size;

    @media screen and (min-width: $containerMin) {
      font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$containerMin}) / #{strip-unit($containerMax - $containerMin)}));
    }

    @media screen and (min-width: $containerMax) {
      font-size: $max-font-size;
    }
  }
}

%font-style-bold {
  font-family: "gt_walsheim_trialrg"
  #{$fontFallbacks};
  font-weight: 700;
  font-style: normal;
}

%font-style-header {
  font-family: "gt_walsheim_trialrg"
  #{$fontFallbacks};
  font-weight: 500;
  font-style: normal;
}

%font-style-regular {
  font-family: "gt_walsheim_trialrg"
  #{$fontFallbacks};
  font-weight: 400;
  font-style: normal;
}

%font-style-light {
  font-family: "gt_walsheim_trialrg"
  #{$fontFallbacks};
  font-weight: 300;
  font-style: normal;
}

%font-title {
  @extend %font-style-header;
  @include fluid-type(20.5px, 24.5px);
  margin-top: 0.58em;
  margin-bottom: 0.925em;
}

%font-subtitle {
  @extend %font-style-header;
  @include fluid-type(15.2px, 16px);
  margin-top: -0.15em;
  margin-bottom: 0.5em;
}

%font-heading {
  @extend %font-style-header;
  @include fluid-type(17.75px, 20px);
  font-size: 20px;
  margin-top: 0.73em;
  margin-bottom: 1.2em;
}

%font-subheading {
  @extend %font-style-regular;
  font-size: 12px;
  margin-top: -0.3em;
  margin-bottom: 0.8em;
}

%font-body {
  @extend %font-style-regular;
  @include fluid-type(12px, 14.1px);
  margin-top: 0;
  margin-bottom: 1em;
}

%font-smallprint {
  @extend %font-style-regular;
  font-size: 10px;
}

%font-hightlightlink {
  @extend %font-heading;
  color: map.get($colours, "red");
}