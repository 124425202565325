@use "sass:map";

$lineheight: 1.5;
$containerMin: 320px;
$containerMax: 1500px;
$containerColumnCount: 12;
$containerGutterSize: 1em;
$paddingBase: 1em;
$marginBase: 1em;
$fontFallbacks: 'Century Gothic',
sans-serif;
$borderRadius:1000px;

$containerBreakpoints: ("SM": 600,
  "MD": 760,
  "LG": 900,
  "XL": 1200,
);

$colours: ("red": #CC0000,
  "white": #FFFFFF,
  "black": #000,
  "lilac": #9F296E,
  "aqua": #2391C1,
  "orange": #E27038,
  "grey-light": #D2D2D2,
  "grey-dark": #323232,
  "red-light": #ED1E26,
  "turquoise": #52E5BA,
);

$colourProfiles: ("primary": map.get($colours, "red"),
  "secondary": map.get($colours, "white"),
  "tertiary": map.get($colours, "grey-light"),
  "text": map.get($colours, "black"),
  "highlight": map.get($colours, "red"),
  "highlight-text": map.get($colours, "white"),
  "error": map.get($colours, "red"),
);

$paddingScales: ("0": 0,
  "025": 0.25,
  "05": 0.5,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "10": 10,
);

$marginScales: ("0": 0,
  "025": 0.25,
  "05": 0.5,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "10": 10,
);