@use "sass:color";
@use "sass:map";
@use "sass:math";
@import "./variables";
@import "./typography";

.form-fields {
  position: relative;
}

.field {
  padding-bottom: 1em;
  position: relative;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.field-input {
  padding-bottom: 1em;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #000 !important;
}

label {
  margin: 0;
  display: inline-block;
  position: relative;
}

.checkbox-option-flex {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
}

.checkbox-option {
 
  input[type=checkbox] {
    flex-grow: 0;
    flex-shrink: 0;
    appearance: none;
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  label {
    flex-grow: 1;
    text-align: left;
    padding-left: 2em;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      user-select: none;
      cursor: pointer;
      transform: translateY(-50%);
    }

    &:before {
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
      border: 1px solid #000;
      background: #{map.get($colourProfiles, "highlight-text")};
    }

    &:after {
      content: '\f00c';
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      color: #{map.get($colourProfiles, "text")};
      left: calc(0.5ch - 0.6px);
      opacity: 0;
    }

    &.box-right {
      padding-left: 0;
      padding-right: 2em;

      &:before {
        left: initial;
        right: 0
      }

      &:after {
        left: initial;
        right: calc(0.5ch - 0.5px);
      }
    }
  }

  input[type=checkbox]:checked+label:after {
    opacity: 1;
  }
}

input[type=text],
input[type=date],
select,
textarea.font-input {
  border: 1px solid #{map.get($colourProfiles, "tertiary")};
  border-radius: 6px;
  padding: 0.66em 0.8em;
  width: 100%;
  margin-top: 0.25em;
  margin-bottom: 1em;

  table & {
    margin-bottom: 0;
  }
}

textarea.font-input {
  width: auto;

  @media (max-width: 500px) {
    width: 100%;
  }
}

input[type=text]+.error,
input[type=date]+.error,
select+.error {
  transform: translate(-50%, -125%);
}

.field-repeater td .error {
  transform: translate(-50%, -63%);
}

.error {
  color: #{map.get($colourProfiles, "error")};
  text-align: center;
  width: 100%;
}

.input-group {
  text-align: center;
}

.input-group div {
  display: inline-block;
  width: 45%;
}

.input-group div.input-group-append {
   border: 1px solid #f00;
   border-radius: 5px;
   margin-left: 10px;
   width: auto;
   padding: 10px;
}


.input-group {
  width: 100%;
}

.referral-link {
  width: 100%;
}


.searchContainer {
  display: inline-flex;
  flex: 1 1 300px;
  position: relative;
  border: 1px solid #2391C1;
  border-radius: 5px;
  overflow: hidden;
}

.searchIcon {
  padding: 0.5rem;
}

.searchBox {
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0;
  flex: 1;
  margin-bottom: auto;
  margin-top: auto;
  padding: 10px;
}

.searchButton {
  background: #2391C1;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 0;
}

.radio-options {

  display: flex;

  & :first-child {
    margin-right: 5px;
  }

  label {
    padding: 5px;
    flex-basis: 50%;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #fff;

    & img {
      width: auto;
    }
  }

  

  .input-hidden {
    position: absolute;
    left: -9999px;
  }
  
  input[type=radio]:checked + label {
    border: 5px solid #000;
  }
}

.cancel-button {
  margin-left: 10px;
}

.clear-button {
  background-color: transparent;
  color: #000;
  margin-left: 10px;
}