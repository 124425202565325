@use "sass:color";
@use "sass:map";
@use "sass:math";
@import "./variables";
@import "./typography";

* {
  min-height: 0;
  min-width: 0;
  box-sizing: border-box;
}

body {
  @extend %font-body;
  font-size: 100%;
  padding: 0;
  margin: 0;
  line-height: $lineheight;
}

h1 {
  @extend %font-title;
}

h2 {
  @extend %font-subtitle;
}

h3,
h4,
h5 {
  @extend %font-heading;
}

p,
label {
  @extend %font-body;
}

p a {
  color: #{map.get($colourProfiles, "highlight")};
}

input {
  @extend %font-body;
  @extend %font-style-light;
}

footer>* {
  @extend %font-smallprint;
}

section {
  margin-bottom: 1.2em;
}

figure {
  margin: 2em auto;
  max-width: 760px;

  img {
    width: 100%;
    display: inline-block;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 5px;
  margin: 0;
  background-color: #e4e4e4;
  border-radius: 5px;
  color: #{map.get($colourProfiles, "highlight")};
  position: relative;
  margin-bottom: 0.2em;
}

progress.secondary[value] {
  background-color: #{color.adjust(map.get($colourProfiles, "highlight-text"), $alpha: -0.6)};
  color: #{map.get($colourProfiles, "highlight-text")};
}

/*
  -webkit-progress-bar -> To style the progress element container
  -webkit-progress-value -> To style the progress element value.
*/

progress[value]::-webkit-progress-bar {
  background-color: #e4e4e4;
  border-radius: 5px;
}

progress.secondary[value]::-webkit-progress-bar {
  background-color: #{color.adjust(map.get($colourProfiles, "highlight-text"), $alpha: -0.6)};
}


progress[value]::-webkit-progress-value {
  position: relative;
  border-radius: 5px;
  background: #{map.get($colourProfiles, "highlight")};
}

progress.secondary[value]::-webkit-progress-value {
  background: #{map.get($colourProfiles, "highlight-text")};
}

progress[value]::-webkit-progress-value:after {
  /* Only webkit/blink browsers understand pseudo elements on pseudo classes. A rare phenomenon! */
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  top: 5px;
  right: 5px;
  background: #{map.get($colourProfiles, "highlight")};
  border-radius: 100%;
}

progress.secondary[value]::-webkit-progress-value:after {
  background: #{map.get($colourProfiles, "highlight-text")};
}

/* Firefox provides a single pseudo class to style the progress element value and not for container. -moz-progress-bar */

progress[value]::-moz-progress-bar {
  background: #{map.get($colourProfiles, "highlight")};
  border-radius: 5px;
}

progress.secondary[value]::-moz-progress-bar {
  background: #{map.get($colourProfiles, "highlight-text")};
}

/* Fallback technique styles */
.progress-bar {
  background-color: #e4e4e4;
  border-radius: 5px;
  width: 100%;
  height: 5px;

  span {
    background: #{map.get($colourProfiles, "highlight")};
    border-radius: 5px;
    display: block;
    text-indent: -9999px;
  }

  &.secondary {
    background-color: #{color.adjust(map.get($colourProfiles, "highlight-text"), $alpha: -0.6)};

    span {
      background: #{map.get($colourProfiles, "highlight-text")};
    }
  }
}

table.leaderboard {
  max-width: 280px;
  width: 100%;

  @media (min-width: #{map.get($containerBreakpoints, "SM")}px) {
    max-width: 380px;
  }

  @media (min-width: #{map.get($containerBreakpoints, "MD")}px) {
    max-width: 450px;
  }

  @media (min-width: #{map.get($containerBreakpoints, "LG")}px) {
    max-width: 600px;
  }

  th {
    padding: 1em 0;
    margin: 0;
    border-bottom: 1px solid #{map.get($colourProfiles, "text")};
  }

  td {
    padding: 0.25em 0;
  }
}

.loader {
  position: relative;
  max-width: 3.5em;
  max-height: 3.5em;
  width: 100%;
  height: 100%;

  .spindle-wrapper {
    width: 100%;
    height: 100%;
    animation-name: spin;
    animation-duration: 1.1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  .spindle {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: calc(50% - 1px);

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        transform: rotate(#{45 * ($i - 1)}deg);
        opacity: #{0.5 + (0.5 * math.div(($i - 1), 8))};
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 25%;
      background: #{map.get($colourProfiles, "highlight")};
      top: 2px;
    }
  }

  &.primary .spindle:before {
    background: #{map.get($colourProfiles, "highlight")};
  }

  &.secondary .spindle:before {
    background: #{map.get($colourProfiles, "highlight-text")};
  }
}

table {
  width: 100%;
}

.training-thumbnail {
  min-height: 80px;

  img {
    min-height: 80px;
    object-fit: cover;
    object-position: right;
  }
}

@media only screen and (max-device-width: 900px) { 
  #legacy-header>.wrapper>.mobile-menu-toggle {
    display: flex;
    justify-content: right;
  }

  #legacy-header>.wrapper>.desktop-menu {
    display: none;
  }
}