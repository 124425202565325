@keyframes loading {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}

@keyframes dropIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}